import React, { useState, useEffect } from 'react';
import './App.scss';
import './Mobile.scss'
import _ from 'lodash'
import SocialLinks from './SocialLinks'
import Photos from './Dossier';
import Player from './Player'
import Contact from './Contact'
import Paint from './Paint'

import Store from './Store/'

import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import Mind from './Mind';

import { GiHamburgerMenu } from 'react-icons/gi'



function App() {
  //const [images, setImages] = useState(0);

  const [play, setPlay] = useState(false);
  const [muted, setMuted] = useState(true);
  const [menu, setMenu] = useState(false);


  const toggleMenu = () => {
    setMenu(!menu)
  }

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    let isPlaying = true;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      isPlaying = true
    } else {
      // production code
    }

    //Try to play, check if it worked, then do muted play
    //GANG SHIT
    setPlay(isPlaying)
    //setMuted(true)

    window.setTimeout(() => {
      // setPlay(true)

    }, 5000)
  });

  const setMute = (isMuted) => {
    setMuted(isMuted)

  }

  return (
    <Router>
      <div>
        <div className="top-bar">
          <div onClick={toggleMenu}><GiHamburgerMenu size="2em" id="menu" /></div>
          <Link to="/"><h1>Natalie</h1></Link>
          <ul className={!menu ? "mobile-hidden" : ""}>
            <li><Link onClick={toggleMenu} to="/dossier">Dossier</Link></li>
            <li><Link onClick={toggleMenu} to="/center">Center</Link></li>
            <li><Link onClick={toggleMenu} to="/paint">Paint</Link></li>
            <li><Link onClick={toggleMenu} to="/contact">Contact</Link></li>
            <li><Link onClick={toggleMenu} to="/store">Store</Link></li>
          </ul>
        </div>

        <div className="app-body">
          <Switch >
            <Route path="/dossier">
              <Photos />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/center">
              <Mind />
            </Route>
            <Route path="/paint">
              <Paint />
            </Route>
            <Route path="/store">
              <Store />
            </Route>
            <Route path="/">
              <Player setMute={setMute} playing={play} muted={muted} />
            </Route>
          </Switch>
        </div>

        <SocialLinks />
      </div>
    </Router>
  );
}


export default App;

/*
const MainVideo = () => (
  <Query
    query={gql`
 {
  mainVideos{
    video{
      url
    }
  }
}
    `}
  >
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;

      return data.mainVideos.map((item) => (
          <video autoplay="true" loop muted src={item.video.url}></video>
      ));
    }}
  </Query>
);


const Photos = () => (
  <Query
    query={gql`
 {
  displayItems{
    title
    media{
      url
      height
      width
    }
  }
}
    `}
  >
    {({ loading, error, data }) => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error :(</p>;

      return(
        _.chunk(data.displayItems.map((item) => (

        <div className={(item.media.height > item.media.width) ? "img-container vertical" : "img-container"}>
          <img src={item.media.url}></img>
        </div>)),data.displayItems.length/3).map(function(group) {
        return <div className="row">{group}</div> }))
    }}
  </Query>
);
*/
