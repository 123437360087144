import React, { useEffect } from 'react';
import './Store.scss';

var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

const Store = () => {

  function loadScript() {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = ShopifyBuyInit;
  }

  function ShopifyBuyInit() {
    var client = window.ShopifyBuy.buildClient({
      domain: 'natweaves.myshopify.com',
      storefrontAccessToken: '0c9ff114ba54b23f0948035b86c2ef96',
    });
    window.ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent('product', {
        id: '7590202310878',
        node: document.getElementById('product-component-1647555222559'),
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: {
          "product": {
            "styles": {
              "description": {
                "color": "white",
                "font-family": "Baskerville, serif",
                "font-size": "16px"
              },
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "80%",
                  "margin-left": "0",
                  "margin-bottom": "50px"
                },
                "text-align": "left"
              },
              "title": {
                "color": "white",
                "font-family": "Baskerville, serif",
                "font-weight": "normal",
                "font-size": "26px"
              },
              "button": {
                "border": "solid 1px white",
                "font-family": "Baskerville, serif",
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px",
                ":hover": {
                  "background-color": "#000000"
                },
                "background-color": "#000000",
                ":focus": {
                  "background-color": "#000000"
                },
                "border-radius": "0px",
                "padding-left": "50px",
                "padding-right": "50px"
              },
              "quantityInput": {
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px"
              },
              "price": {
                "color": "white",
                "font-size": "18px"
              },
              "compareAt": {
                "font-size": "15.299999999999999px"
              },
              "unitPrice": {
                "font-size": "15.299999999999999px"
              }
            },
            "buttonDestination": "checkout",
            "layout": "horizontal",
            "contents": {
              "img": false,
              "imgWithCarousel": true,
              "description": true
            },
            "width": "100%",
            "text": {
              "button": "Buy Now"
            }
          },
          "productSet": {
            "styles": {
              "products": {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px"
                }
              }
            }
          },
          "modalProduct": {
            "contents": {
              "img": false,
              "imgWithCarousel": true,
              "button": false,
              "buttonWithQuantity": true
            },
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px"
                }
              },
              "button": {
                "font-family": "Baskerville, serif",
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px",
                ":hover": {
                  "background-color": "#000000"
                },
                "background-color": "#000000",
                ":focus": {
                  "background-color": "#000000"
                },
                "border-radius": "0px",
                "padding-left": "50px",
                "padding-right": "50px",
                "border": "solid 2px white",
              },
              "quantityInput": {
                "font-size": "16px",
                "padding-top": "16px",
                "padding-bottom": "16px",
                "color": "white"
              },
              "title": {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "bold",
                "font-size": "26px",
                "color": "#4c4c4c"
              },
              "price": {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "18px",
                "color": "#4c4c4c"
              },
              "compareAt": {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "15.299999999999999px",
                "color": "#4c4c4c"
              },
              "unitPrice": {
                "font-family": "Helvetica Neue, sans-serif",
                "font-weight": "normal",
                "font-size": "15.299999999999999px",
                "color": "#4c4c4c"
              }
            },
            "text": {
              "button": "Buy Now"
            }
          },
          "option": {}
        },
      });
    });
  }



  useEffect(() => {
    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        ShopifyBuyInit();
      } else {
        loadScript();
      }
    } else {
      loadScript();
    }

  }, [])

  return (<div >
    <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "4em"}}>
      <div id='product-component-1647555222559'></div>
    </div>
  <div style={{width: "100%", height: "4em"}}> </div>
  </div>)
}

export default Store;
