import React from 'react';
import './Background.scss'

import { Query } from "react-apollo";
import gql from "graphql-tag";

const Background = () => {
    let list = [];

    for (let i = 0; i < 100; i++) {
        list.push(<div className="background-item"><span>{i}</span></div>)
    }

    return (

        <div className="background-supercontainer">
            <Test />
        </div>)
}

export default Background;



const Test = () => {
    return (
        <Query
            query={gql`
     {
      soundWaves{
        quote
        image{
          url
          height
          width
        }
      }
    }
        `}

        >
            {({ loading, error, data }) => {
                if (loading) return <p>Loading...</p>;
                if (error) return <p>Error :(</p>;
                let list = []

                data.soundWaves.forEach((item) => {
                    for (let i = 0; i < 3; i++) {
                        list.push(<div className="background-item"><span>{list.length + 1}</span></div>)
                    }
                    list.push(<div className="background-item">
                        <span>{list.length + 1}</span>
                        <div className="mind-item">
                            <div dangerouslySetInnerHTML={{ __html: item.quote }} className="mind-quote">

                            </div>

                        </div>
                    </div>)

                    list.push(<div className="background-item">
                        <span>{list.length + 1}</span>
                        <div className="mind-item">

                            <div className="mind-image">
                                <img src={item.image.url}></img>
                            </div>
                        </div>
                    </div>)

                })

                return (
                    <div className="background-container">
                        {list}
                    </div>

                )
            }}
        </Query>
    )
}