
import { Query } from "react-apollo";
import gql from "graphql-tag";
import React, { useState, useEffect } from 'react';
import './Mind.scss';
import Background from './Background';
  
  const Mind = () => (
    <div className="mind-page">
      <Background />
      {/*
    <Query
      query={gql`
   {
    soundWaves{
      quote
      image{
        url
        height
        width
      }
    }
  }
      `}

    >
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;
            console.log(data)
        return(
          data.soundWaves.map((item,index) => (
  
          <div className="mind-item">
            <div dangerouslySetInnerHTML={{__html:item.quote}} className="mind-quote">
            
            </div>
            <div className="mind-image">
                <img src={item.image.url}></img>
            </div>
          </div>)))
      }}
    </Query>
    */}
    </div>
  );

export default Mind;
  