import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player'
import './Player.scss'

const NEW_VIDEO = "https://www.youtube.com/watch?v=D869MmQnkJQ";

const Player = (props) => {

    const [videoCount, setVideoCount] = useState(0);

    //Use https://www.epochconverter.com/ && https://www.epochconverter.com/timezones?q=1634752800&tz=America%2FLos_Angeles

    let currentTime = new Date()
    let featureReleaseTime = new Date(1634752800 * 1000)
    let videoLinks = ["https://www.youtube.com/watch?v=UIIrOOX9VHw", "https://www.youtube.com/watch?v=i1hzTY1qf7k", "https://www.youtube.com/watch?v=8VMDNP7ivfs"]

    if (currentTime > featureReleaseTime) {
        videoLinks.unshift(NEW_VIDEO)
    }

    const videoURL = videoLinks[videoCount % videoLinks.length]

    const handleVideoEnd = () => {
        if (videoCount < videoLinks.length - 1) {
            setVideoCount(videoCount + 1);
        }
    }


    return (
        <div className="video-container">
            <div className="top-video-cover"></div>

            <ReactPlayer onError={handleVideoEnd} onEnded={handleVideoEnd} muted={props.muted} controls={false} playing={props.playing} url={videoURL} config={{
                youtube: { playerVars: { showinfo: 1, modestbranding: 1 } }
            }}
                width='100%'
                height='100%' />
            <div className="bottom-video-cover">
                {props.muted ? <button id="mute-btn" className="mute-button" onClick={() => { props.setMute(false) }}>un-mute</button> : <button onClick={() => { props.setMute(true) }} className="mute-button">Mute</button>}
            </div>
        </div>
    )
}

export default Player;