
import { Query } from "react-apollo";
import gql from "graphql-tag";
import React, { useState, useEffect } from 'react';

/*export const GET_PHOTOS = gql`
query MainGallery {
    gallery: imageSet(where: {id: "ckovgod34xtc20a27vay1qjqh"}, stage: DRAFT) {
      images: image {
        id
        url
        width
        height
      }
    }
  }
  
`;*/
// Old query
// {
//     displayItems{
//       title
//       media{
//         url
//         height
//         width
//       }
//     }
//   }
  
  const Photos = () => (
    <div className="dossier-container">
    <Query
      query={gql`
      {
        dossier: dossierSorted(where: {id: "ckovgod34xtc20a27vay1qjqh"}) {
           photos{
     
             url
             height
             width
     
           }
       }
     }
      `}

    >
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <p>Error :(</p>;
  
        return(
          data.dossier.photos.map((item,index) => {
            const newItem = {media: item, title: ""}
           return <SingleImage item={newItem} index={index} />})
        )
      }}
    </Query>
    </div>
  );

const SingleImage = (props) => {

const [loaded,setLoaded] = useState(false)
let className = ""


if( loaded){
  className = (props.item.media.height > props.item.media.width) ? "img-container vertical" : "img-container"
}else{
  className = "image-loader"
}

  return(
  <div className={className}>
  <span>{props.index}</span>
  <img onLoad={ () => {setLoaded(true)}} src={props.item.media.url}></img>
</div>)
}

export default Photos;


  