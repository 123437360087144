import React from 'react';
import './Contact.scss';

const Contact = () => {
    return (
        <div className="contact-page">

            <div className="form-container">
                <h2>Subscribe</h2>
                <form name="email-list" method="post">
                    <input type="hidden" name="form-name" value="email-list" />
                    <label hidden htmlFor="first-name">Your Name</label>
                    <input type="text" id="first-name" placeholder="First Name" name="name" />
                    <label hidden htmlFor="email">Email Address</label>
                    <input type="text" id="email" placeholder="Email" name="email" />
                    <button type="submit">Subscribe</button>
                </form>
            </div>

            <div className="form-container">
                <h2>Contact</h2>
                <form name="contact" method="post">
                    <input type="hidden" name="form-name" value="contact" />
                    <label hidden htmlFor="first-name">Your Name</label>
                    <input type="text" id="first-name" placeholder="Full Name" name="name" />
                    <label hidden htmlFor="email">Email Address</label>
                    <input type="text" id="email" placeholder="Email" name="email" />
                    <label hidden htmlFor="email">Message</label>
                    <textarea name="message" placeholder="Message"></textarea>
                    <button type="submit">Send</button>
                </form>
            </div>
        </div>
    )
}

export default Contact;