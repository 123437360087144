import React, { useState, useEffect } from 'react';
import ApolloClient from "apollo-boost";
import './Social.scss'

import { FaInstagram, FaYoutube } from 'react-icons/fa'
import { FaApple } from 'react-icons/fa'
import { FaSpotify } from 'react-icons/fa'

const client = new ApolloClient({
  uri: "https://api-uswest.graphcms.com/v1/ck5eokxriabm601gi732ycnfm/master"
});

const SocialLinks = () => (
  <div className="social-links">
    <div className="color-container">
      <div></div>
      <div></div>
    </div>
    <a href="https://www.instagram.com/natweaves/"><FaInstagram size={"1.5em"} /></a>
    <a href="http://itunes.apple.com/album/id/1499308107"><FaApple size={"1.5em"} /></a>
    <a href="https://open.spotify.com/album/6MVjd6lr6oml18gZOP4VyJ"><FaSpotify size={"1.5em"} /></a>
    <a href="https://www.youtube.com/watch?v=8VMDNP7ivfs"><FaYoutube size={"1.5em"} /></a>
  </div>
);

export default SocialLinks;