import React from 'react';
import './Contact.scss';

const Paint = () => {
    return (
        <div className="paint-page">
            <iframe src="https://jspaint.app/#load:https://media.graphcms.com/dBmOcFWHQDS06X4atJn5"></iframe>
        </div>
    )
}

export default Paint;